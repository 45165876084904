const mapElement = document.getElementById("quantum-map");
const latitude = "49.1813";
const longitude = "-2.0781";

mapboxgl.accessToken =
  "pk.eyJ1IjoiaGFtaWx0b25icm9va2UiLCJhIjoiY20zNW50b2V5MDV5YjJpcXk5NWhiMHc2ZiJ9.KynaOKPYPaWIIKFEmSS5Ig";
const map = new mapboxgl.Map({
  container: "quantum-map",
  style: "mapbox://styles/mapbox/streets-v11",
  center: [longitude, latitude],
  zoom: 14,
});

const svgMarker = document.createElement("div");
svgMarker.innerHTML = `
  <svg width="42" height="64" viewBox="0 0 42 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.33,64c1.28-21.3,19.67-26.7,19.67-42.7,0-5.9-2.36-11.2-6.2-15.1-3.74-3.8-8.95-6.2-14.75-6.2S10.03,2.4,6.2,6.2C2.36,10.1,0,15.4,0,21.3c0,16,18.39,21.3,19.67,42.7h2.66Z" fill="#171c5c"/><path d="M37.54,21.07c0,1.44-.2,2.87-.58,4.26h-6.63c2.36-5.15.1-11.24-5.04-13.6-1.3-.6-2.71-.92-4.15-.93-5.65.19-10.09,4.92-9.9,10.58.18,5.39,4.51,9.72,9.9,9.9.62,0,1.24-.06,1.86-.18v6.12c-.62.07-1.24.11-1.86.11-9.04,0-16.4-7.29-16.4-16.25S12.09,4.68,21.14,4.68s16.4,7.34,16.4,16.4" fill="#fff"/><path d="M33.78,31.23l3.67-3.68h-12.23s0,12.22,0,12.22l3.68-3.68,4.11,4.11c1.48-1.86,2.93-3.67,4.22-5.52l-3.45-3.45Z" fill="#fff"/>
  </svg>
`;
svgMarker.style.width = "42px";
svgMarker.style.height = "64px";

new mapboxgl.Marker(svgMarker, { anchor: "bottom" })
  .setLngLat([longitude, latitude])
  .addTo(map);
